import { Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { BusinessDetails, DocumentationTypes, User } from '../../../types';
import { useMobile } from '../../../hooks/use-mobile';
import FileFormPlaceholder from '../../file-form-placeholder';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import EditButton from '../../nuqlea-components/edit-button';
import filesClient from '../../../clients/filesClient';

export interface CreditProfileDetailsProps {
	switchEditMode: (isEditionMode: boolean) => void;
	handleDelete: (creditProfile: BusinessDetails) => void;
	creditProfile: BusinessDetails;
	user: User;
}

const CreditProfileDetails: React.FC<CreditProfileDetailsProps> = ({
	switchEditMode,
	creditProfile,
	handleDelete,
	user,
}) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const getContent = (type: Number) => {
		const filteredFiles = creditProfile.files?.filter((file: any) => Number(file.type) === type);

		return filteredFiles?.length > 0 ? (
			<Grid container spacing={2}>
				{filteredFiles.map((file: any) => {
					let adaptedFile = { ...file };
					
					adaptedFile.name = file.name;
					
					return (
						<Grid item xs={6}>
							<FileFormPlaceholder 
								file={adaptedFile} 
								user={user} 
								previewCallback={filesClient.downloadMetadataFile} 
								downloadCallback={filesClient.downloadBinaryFile}/>
						</Grid>
					);
				})}
			</Grid>
		) : null;
	};

	const data = [
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.last_balance_sheets_input' }).split('(')[0],
			content: <Box>{getContent(DocumentationTypes.BALANCE)}</Box>,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.sales_after_balance_input' }).split('(')[0],
			content: <Box>{getContent(DocumentationTypes.BALANCE_POST_SALES)}</Box>,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.commercial_reference' }),
			content: getContent(DocumentationTypes.COMMERCIAL_REF) ?? (
				<Typography whiteSpace={'pre-line'} variant="body2">
					{creditProfile.commercial_reference}
				</Typography>
			),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_suppliers' }),
			content: getContent(DocumentationTypes.MAIN_SUPPLIERS) ?? (
				<Typography whiteSpace={'pre-line'} variant="body2">
					{creditProfile.supplier}
				</Typography>
			),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_customers' }),
			content: getContent(DocumentationTypes.MAIN_CUSTOMERS) ?? (
				<Typography whiteSpace={'pre-line'} variant="body2">
					{creditProfile.customer}
				</Typography>
			),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.projected_cashflow_over_12_months' }),
			content: <Box>{getContent(DocumentationTypes.PROJ_CASH_FLOW_OVER_12_MONTHS)}</Box>,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.credit_profile_tab.financial_debt' }),
			content: <Box>{getContent(DocumentationTypes.FINANCIAL_DEBT)}</Box>,
		}
	];

	return (
		<GenericTabFrame
			variant="card"
			buttons={[
				<EditButton onClick={() => switchEditMode(true)}>{intl.formatMessage({ id: 'common.edit' })}</EditButton>,
				<Button onClick={() => handleDelete(creditProfile)}>{intl.formatMessage({ id: 'common.delete' })}</Button>,
			]}
		>
			<Stack>
				{data.map((item, index) => (
					<Grid container flexGrow={1} key={item.title}>
						{index !== 0 && (
							<Grid item xs={12}>
								<Divider className="my-3" orientation="horizontal"></Divider>
							</Grid>
						)}
						<Grid container>
							<Grid item xs={12}>
								<Typography fontWeight={700}>{item.title}</Typography>
							</Grid>
							<Grid item className="pt-2" xs={12}>
								{item.content}
							</Grid>
						</Grid>
					</Grid>
				))}
			</Stack>
		</GenericTabFrame>
	);
};

export default CreditProfileDetails;
