import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Link,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	RedeemRequestStatus,
	RedeemRequestTypes,
	Document,
	Delivery,
	Role,
	RedeemTypesMapper,
	StackedData,
} from '../../../types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FileUploadDialog from '../../details/dialogs/file-upload-dialog';
import TableCardTreeView from '../../table-card-tree-view';
import TableCard from '../../table-card';
import { useIntl } from 'react-intl';
import { InfoOutlined, ThumbDown, ThumbUp, Upload } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DeliveryContext } from '../../../contexts/deliveryContext';
import {
	relatedDeliveries,
	relatedFiles,
	relatedDeliveryFiles,
	getConstructorRelatedProductsColumns,
} from './RedeemViewHelper';
import client from '../../../clients/client';
import { useParams, useNavigate } from 'react-router-dom';
import BudgetAcceptanceDialog from '../BudgetAcceptanceDialog';
import RejectedRedeemTokensDialog from '../dashboard/dialogs/RejectedRedeemTokensDialog';
import DeliveryAddress from '../../delivery-address';
import userState from '../../../atoms/user-state';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SellerDeliveryModal from './deliveries/seller-delivery-modal';
import ConstructorDeliveryModal from './deliveries/constructor-delivery-modal';
import EndRedeemDialog from './dialog/EndRedeem';
import Error from '../../error';
import BasePageFrame from '../../base-components/base-page-frame';
import StatusCardV2, { Status } from '../../status-card-v2';
import StackDataDisplay from '../../base-components/stack-data-display';

const sellerAllowedFileTypes = [
	{ id: 6, name: 'Factura' },
	{ id: 13, name: 'Nota de crédito' },
	{ id: 15, name: 'Nota de débito' },
	{ id: 14, name: 'Recibo de pago' },
];

const constructorAllowedFileTypes = [
	{ id: 7, name: 'Orden de compra' },
	{ id: 9, name: 'Comprobante de pago' },
	{ id: 10, name: 'Comprobante de retenciones' },
	{ id: 11, name: 'Órden de pago' },
	{ id: 23, name: 'Plano cortado y doblado' },
];

const Redeemview = () => {
	const user = useRecoilValue(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);

	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [data, setData] = useState<any>(null);
	const [redeemRequestDetails, setRedeemRequestDetails] = useState<StackedData[]>([]);
	const [error, setError] = useState<any>(null);
	const [openRedeemModal, setOpenRedeemModal] = useState(false);
	const { id } = useParams();
	const intl = useIntl();
	const navigate = useNavigate();

	const [message, setMessage] = useState<any>();
	const [baseInfo, setBaseInfo] = useState<any>();
	const [deliveryModal, setDeliveryModal] = useState<{ data: Delivery; modalType: 'new' | 'edit' | 'view' } | null>(
		null
	);
	const [rrFiles, setRRFiles] = useState<any[]>([]);

	const [showBudgetAcceptanceDialog, setShowBudgetAcceptanceDialog] = useState(false);
	const [showBudgetRejectDialog, setShowBudgetRejectDialog] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);

	const init = useCallback(async () => {
		setShowBudgetAcceptanceDialog(false);
		setData(null);

		try {
			const { redeem_request: redeemRequest } = await client.fetchRedeemRequest(user.token, id as string);

			if (!redeemRequest) {
				setError({
					message: 'No se encontró la solicitud de canje o no estás autorizado para verla',
				});
				setLoadingDialog(false);
				return;
			}

			const [
				{ redeem_request_products: redeemRequestProducts },
				{ redeem_request_files: redeemRequestFiles },
				{ redeem_request_shippings: redeemRequestShippings },
				{ redeem_request_events: redeemRequestEvents },
			] = await Promise.all([
				client.fetchRedeemRequestProducts(user.token, id as string),
				client.fetchRedeemRequestFiles(user.token, id as string),
				client.fetchRedeemRequestShippings(user.token, id as string),
				client.fetchRedeemRequestEvents(user.token, id as string),
			]);

			let statusPill: 'aceptado' | null = null;
			if (
				user.type_external === Role.CONSTRUCTOR &&
				redeemRequest?.status > 2 &&
				redeemRequest?.type === RedeemRequestTypes.SHIPPING
			) {
				statusPill = 'aceptado';
			}

			setRRFiles(
				redeemRequestFiles.map((item) =>
					item.label_tipo_archivo === 'Presupuesto de envio' ? { ...item, status: statusPill } : item
				)
			);

			const redeemProductsMap = new Map();
			redeemRequestShippings.forEach((shipping: any) => {
				// iterate shippings to get the quantity of delivered, pending to deliver and unassigned products
				shipping.products.forEach((product: any) => {
					if (redeemProductsMap.has(product.redeem_request_product_id)) {
						const { pending, delivered } = redeemProductsMap.get(product.redeem_request_product_id);
						if (shipping.status === 1) {
							redeemProductsMap.set(product.redeem_request_product_id, {
								pending: pending + product.quantity,
								delivered,
							});
						} else if (shipping.status === 2) {
							redeemProductsMap.set(product.redeem_request_product_id, {
								pending,
								delivered: delivered + product.quantity,
							});
						}
					} else {
						const pending = shipping.status === 1 ? product.quantity : 0;
						const delivered = shipping.status === 2 ? product.quantity : 0;
						redeemProductsMap.set(product.redeem_request_product_id, { pending, delivered });
					}
				});
			});

			const redeemRequestProductsUpdated: any[] = redeemRequestProducts.map((product: any) => {
				// update the array of products with the quantity of delivered and pending products
				const { pending, delivered } = redeemProductsMap.get(product.id) || { pending: 0, delivered: 0 };
				return {
					...product,
					pending,
					delivered,
					unassigned: product.quantity - pending - delivered,
				};
			});

			const data = {
				redeemRequest,
				redeemRequestProducts: redeemRequestProductsUpdated,
				redeemRequestShippings: redeemRequestShippings.map((delivery: any) => ({
					...delivery,
					id: delivery.entrega_id,
					type: delivery.tipo_entrega_id,
					estimated_delivery_date: delivery.fecha_pactada,
					delivery_date: delivery.fecha_entrega,
					comment: delivery.comentarios_entrega,
				})),
				redeemRequestEvents: redeemRequestEvents.filter((event: any) => event.action_type === 1),
			};

			setData(data);
			setBaseInfo({
				metadata: {
					files_folder_id: redeemRequest.files_folder_id,
					redeem_request_id: redeemRequest.id,
				},
			});
			setRedeemRequestDetails([
				{
					title: intl.formatMessage({ id: 'mytokens.redeem_view.seller.details.tokens_quantity' }),
					description: intl.formatMessage(
						{ id: 'mytokens.redeem_view.seller.details.tokens_quantity_description' },
						{ value: intl.formatNumber(data.redeemRequest.stock, { style: 'decimal' }) }
					),
				},
				{
					title: intl.formatMessage({ id: 'mytokens.redeem_view.seller.details.shipping_type' }),
					description: intl.formatMessage({ id: 'common.' + data.redeemRequest.type }),
				},
				{
					title: intl.formatMessage({ id: 'mytokens.redeem_view.seller.details.address' }),
					description: '-',
					objectDesc:
						data?.redeemRequest?.type === RedeemRequestTypes.WITHDRAWAL ? (
							<Typography variant={'body2'} className='text-right'>
								<Link
									target='_blank'
									href={data?.redeemRequest?.address?.place_url}
									className='cursor-pointer'
									underline='none'
								>
									{data?.redeemRequest?.address?.address}
								</Link>
							</Typography>
						) : (
							<DeliveryAddress
								showTitle={false}
								useLink={data?.redeemRequest?.address ? true : false}
								user={user}
								address={data?.redeemRequest?.address?.id ?? data?.redeemRequest?.address?.address ?? ''}
								withdrawal={false}
								typographyProps={{ variant: 'body2', color: 'blue' }}
							/>
						),
				},
				{
					title: intl.formatMessage({ id: 'common.agreed_date' }),
					description: data.redeemRequest.date
						? intl.formatDate(data.redeemRequest.date, { timeZone: 'UTC' })
						: intl.formatMessage({ id: 'mytokens.redeem_view.scheduled_deliveries' }),
				},
			]);
		} catch (error) {
			setError({
				message: 'No se encontró la solicitud de canje o no estás autorizado para verla',
			});
			setLoadingDialog(false);
			return;
		}
	}, [id, user.token, user.type_external]);

	const handleGoBack = () => {
		navigate(-1);
	};

	const fetchFiles = async () => {
		const { redeem_request_files: redeemRequestFiles } = await client.fetchRedeemRequestFiles(user.token, id as string);
		setRRFiles(redeemRequestFiles);
	};

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = rrFiles || [];

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el: Document) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setRRFiles(allNewDocs);
		} else {
			setRRFiles([
				...rrFiles,
				{ ...newFile, label_tipo_archivo: newFile.label_file_type, file: newFile.drive_file_id },
			]);
		}

		if (newFile.file_type_id === 9 && data?.redeemRequest?.status === 6) {
			setData({
				...data,
				redeemRequest: {
					...data.redeemRequest,
					status: 7,
				},
			});
		} else if (newFile.file_type_id === 6 && data?.redeemRequest?.status === 5) {
			setData({
				...data,
				redeemRequest: {
					...data.redeemRequest,
					status: 6,
				},
			});
		}
	};

	const handleReject = (content: string) => {
		setLoadingDialog(true);
		client
			.redeemConstructorAction({
				fields: {
					redeem_request_id: data?.redeemRequest?.id,
					action_type: 3,
					comments: content,
					result: 6,
				},
				token: user.token,
			})
			.then(() => {
				navigate(`/my-tokens?agreement=${data?.redeemRequest?.agreement_id}`);
			});
	};

	const handleAccept = () => {
		setLoadingDialog(true);
		client
			.redeemConstructorAction({
				fields: {
					redeem_request_id: data?.redeemRequest?.id,
					action_type: 3,
					comments: '',
					result: 5,
				},
				token: user.token,
			})
			.then(() => {
				// setShowBudgetAcceptanceDialog(false)
				setTimeout(() => {
					init();
				}, 2500);
				// navigate(`/my-tokens?agreement=${data?.redeemRequest?.agreement_id}`);
			});
	};

	useEffect(() => {
		init();
	}, [user.token, id, init]);

	useEffect(() => {
		setDisableRootElementScroll(
			Boolean(
				deliveryModal || fileUploadDialogOpen || showBudgetAcceptanceDialog || showBudgetRejectDialog || openRedeemModal
			)
		);
	}, [
		deliveryModal,
		fileUploadDialogOpen,
		showBudgetAcceptanceDialog,
		showBudgetRejectDialog,
		openRedeemModal,
		setDisableRootElementScroll,
	]);

	const onBudgetRejectClick = () => {
		setShowBudgetRejectDialog(true);
	};

	const onBudgetAcceptClick = () => {
		setShowBudgetAcceptanceDialog(true);
	};

	const newDelivery = useMemo(() => {
		return {
			products: data?.redeemRequestProducts.map((product: any) => ({
				redeem_request_product_id: product.id,
				redeem_request_product_product_id: product.product_id,
				quantity: 0,
			})),
			estimated_delivery_date: '',
			delivery_date: null,
			comment: '',
			status: 1, // pending
			files: [{ file: undefined, id_doc: '' }],
		};
	}, [data?.redeemRequestProducts]);

	const counts: Status[] =
		user.type_external === Role.SELLER
			? !data?.redeemRequest?.shipping_price
				? [
						{ label: RedeemRequestStatus.REQUEST_RECEIVED, active: false, completed: true },
						{
							label: RedeemRequestStatus.REDEEM_APPROVED,
							active: false,
							completed: true,
							tooltip: intl.formatMessage(
								{ id: 'mytokens.redeem_view.seller.stepper.redeem_aproved_tooltip' },
								{
									date:
										intl.formatDate(data?.redeemRequestEvents?.[0]?.created_at) +
										', ' +
										intl.formatTime(data?.redeemRequestEvents?.[0]?.created_at, { hour12: false }) +
										'hs',
								}
							),
							alwaysShowTooltip: true,
						},
						{
							label: RedeemRequestStatus.PENDING_RELEASE,
							active: data?.redeemRequest?.status === 2,
							completed: data?.redeemRequest?.status > 2,
						},
						{ label: RedeemRequestStatus.DELIVERED, active: false, completed: data?.redeemRequest?.status > 2 },
				  ]
				: [
						{
							label: RedeemRequestStatus.QUOTE_SENT,
							active: [1].includes(data?.redeemRequest?.status),
							completed: ![1].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.AWAITING,
							active: [2].includes(data?.redeemRequest?.status),
							completed: ![1, 2].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.INVOICE,
							active: [5].includes(data?.redeemRequest?.status),
							completed: ![1, 2, 3, 5].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.PAYMENT,
							active: [6].includes(data?.redeemRequest?.status),
							completed: ![1, 2, 3, 5, 6].includes(data?.redeemRequest?.status),
						},
						{
							label: RedeemRequestStatus.SHIPPING,
							active: [7].includes(data?.redeemRequest?.status),
							completed: ![1, 2, 3, 5, 6, 7].includes(data?.redeemRequest?.status),
							// active: data?.redeemRequest?.status === 4 && data?.redeemRequestShippings.findIndex((elem: any) => elem.status === 1) !== -1,
							// completed: data?.redeemRequest?.status === 4 && data?.redeemRequestShippings.findIndex((elem: any) => elem.status === 1) === -1
						},
				  ]
			: !data?.redeemRequest?.shipping_price
			? [
					{ label: RedeemRequestStatus.REQUEST_RECEIVED, active: false, completed: true },
					{
						label: RedeemRequestStatus.REDEEM_APPROVED,
						active: data?.redeemRequest?.status === 1,
						completed: data?.redeemRequest?.status > 1,
						tooltip:
							data?.redeemRequest?.status === 1
								? intl.formatMessage({ id: 'mytokens.constructor.redeem.stepper.redeem_approval_pending_tooltip' })
								: intl.formatMessage(
										{ id: 'mytokens.constructor.redeem.stepper.redeem_aproved_tooltip' },
										{
											date:
												intl.formatDate(data?.redeemRequestEvents?.[0]?.created_at) +
												', ' +
												intl.formatTime(data?.redeemRequestEvents?.[0]?.created_at, { hour12: false }) +
												'hs',
										}
								  ),
						alwaysShowTooltip: true,
					},
					{
						label: RedeemRequestStatus.PENDING_RELEASE,
						active: data?.redeemRequest?.status === 2,
						completed: data?.redeemRequest?.status > 2,
						tooltip: intl.formatMessage({ id: 'mytokens.redeem_view.seller.stepper.pending_release_tooltip' }),
						alwaysShowTooltip: true,
					},
					{ label: RedeemRequestStatus.DELIVERED, active: false, completed: data?.redeemRequest?.status > 2 },
			  ]
			: [
					{
						label: RedeemRequestStatus.REQUEST_SENT,
						active: [1].includes(data?.redeemRequest?.status),
						completed: ![1].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.QUOTE_RECEIVED,
						active: [2].includes(data?.redeemRequest?.status),
						completed: ![1, 2].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.INVOICE,
						active: [5].includes(data?.redeemRequest?.status),
						completed: ![1, 2, 3, 5].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.AWAITING_PAYMENT,
						active: [6].includes(data?.redeemRequest?.status),
						completed: ![1, 2, 3, 5, 6].includes(data?.redeemRequest?.status),
					},
					{
						label: RedeemRequestStatus.SHIPPING,
						active: [7].includes(data?.redeemRequest?.status),
						completed: ![1, 2, 3, 5, 6, 7].includes(data?.redeemRequest?.status),
					},
			  ];

	if (error) {
		return <Error />;
	}

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.redeem_view.constructor.title' })}
			transparent
			returnAction={handleGoBack}
			childContainerStyles='mt-[-1.2rem]'
			titleContainerStyles='items-center flex gap-1'
			endAdornment={
				<Box className='flex flex-col md:flex-row mt-[-0.5rem]'>
					{!!data?.redeemRequest?.created_at && (
						<>
							{data?.redeemRequest?.status !== 4 &&
								data?.redeemRequest?.status !== 8 &&
								user.type_external === Role.SELLER && (
									<Tooltip
										title={
											data?.redeemRequestShippings.some((elem: any) => elem.status === 1)
												? 'Todas las entregas deben estar finalizadas'
												: data.redeemRequestShippings.length === 0
												? 'No hay entregas para finalizar el canje'
												: ''
										}
										placement='top'
									>
										<Stack direction={'row'} spacing={3}>
											<Button
												disabled={
													data?.redeemRequestShippings.some((elem: any) => elem.status === 1) ||
													data.redeemRequestShippings.length === 0
												}
												onClick={() => setOpenRedeemModal(true)}
												className='md:ml-4 mt-2 md:mt-0'
												variant='outlined'
											>
												{intl.formatMessage({ id: 'mytokens.redeem.end_button' })}
											</Button>
										</Stack>
									</Tooltip>
								)}
						</>
					)}
				</Box>
			}
		>
			<Grid container spacing={2}>
				{data?.redeemRequest.returned_tokens > 0 && (
					<Grid item xs={12}>
						<Box className='flex items-center w-full p-4 rounded-md bg-blue-100' sx={{ borderLeft: '8px solid blue' }}>
							<InfoOutlined sx={{ mr: 1.5, color: 'blue' }} />
							<Typography variant='body1' color='blue'>
								{intl.formatMessage(
									{ id: `mytokens.redeem.shipping.${user.type_external}.info_returned_tokens` },
									{ returnedTokens: intl.formatNumber(data.redeemRequest?.returned_tokens, { style: 'decimal' }) }
								)}
							</Typography>
						</Box>
					</Grid>
				)}
				<Grid item xs={12}>
					{!data?.redeemRequest ? (
						<Skeleton variant='rectangular' height={200} />
					) : (
						<StatusCardV2 titleLocation='center' statuses={counts || []} elevation={0} />
					)}
				</Grid>

				<Grid item xs={12} sm={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
					{!data?.redeemRequest ? (
						<Skeleton variant='rectangular' height={300} />
					) : (
						<Card elevation={0} sx={{ height: '100%' }}>
							<CardHeader
								title={intl.formatMessage({ id: 'mytokens.redeem_view.seller.details.title' })}
								subheader={intl.formatDate(data?.redeemRequest?.created_at, { timeZone: 'UTC' })}
								subheaderTypographyProps={{ variant: 'body2' }}
								sx={{ p: 4, minHeight: '130px' }}
							></CardHeader>
							<CardContent sx={{ px: 4, pt: 0 }}>
								<Grid container spacing={2} justifyContent='space-between'>
									<Grid item xs={12}>
										<StackDataDisplay
											data={redeemRequestDetails}
											descriptionTextAlign='right'
											paddingTop={'8px'}
										></StackDataDisplay>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					)}
				</Grid>
				<Grid item xs={12} sm={7}>
					{!data?.redeemRequest ? (
						<Skeleton variant='rectangular' height={300} />
					) : (
						<TableCard
							elevation={0}
							borderRadius={1}
							infiniteScroll={false}
							title={intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.related_products.title' })}
							subtitle={
								<Typography variant='body2'>
									{data?.redeemRequestProducts.length}{' '}
									{data &&
										intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.related_products.subtitle' })}
								</Typography>
							}
							columns={getConstructorRelatedProductsColumns(intl)}
							disabledWidthForUnsortedColumns
							rows={data?.redeemRequestProducts.map((product: any) => ({
								...product,
								unity: data?.redeemRequest?.unity,
							}))}
							loading={!data?.redeemRequestProducts}
							emptyMessage={intl.formatMessage({
								id: 'mytokens.seller.dashboard.redeem_request.related_products.empty_text',
							})}
							sx={{ height: '100%', maxHeight: '490px' }}
							maxTableHeight='340px'
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					{!data?.redeemRequest ? (
						<Skeleton variant='rectangular' height={300} />
					) : (
						<DeliveryContext.Provider value={{ baseInfo, message, setMessage, setDeliveryModal }}>
							<Grid item xs={12}>
								<TableCardTreeView
									elevation={0}
									borderRadius={1}
									loading={!data?.redeemRequestShippings}
									title={intl.formatMessage({ id: 'list.seller.details.related_deliveries.title' })}
									layoutColumns={12}
									columns={relatedDeliveries}
									relatedColumns={relatedDeliveryFiles}
									rows={data?.redeemRequestShippings?.map((delivery: Delivery) => ({
										...delivery,
										user,
										unity: data?.redeemRequest?.unity ?? '',
									}))}
									emptyMessage={
										user.type_external === Role.SELLER
											? intl.formatMessage({ id: 'mytokens.seller.redeem.deliveries.empty_state' })
											: intl.formatMessage({ id: 'mytokens.constructor.redeem.deliveries.empty_state' })
									}
									actions={
										user.type_external === Role.SELLER && !!data && data.redeemRequest.status === 2
											? [
													{
														displayName: intl.formatMessage({
															id: 'list.seller.details.deliveries.new_delivery.button_label',
														}),
														icon: <Upload />,
														type: 'Button',
														onClick: () => setDeliveryModal({ data: newDelivery as Delivery, modalType: 'new' }),
													},
											  ]
											: []
									}
								></TableCardTreeView>
							</Grid>
							{deliveryModal &&
								(user.type_external === Role.SELLER ? (
									<SellerDeliveryModal
										open={Boolean(deliveryModal)}
										delivery={deliveryModal.data}
										modalType={deliveryModal.modalType}
										onClose={(event, reason) => {
											setDeliveryModal(null);

											if (['success'].includes(reason ?? '')) init();
										}}
										deliveryType={data.redeemRequest.type}
										redeemRequestProducts={data.redeemRequestProducts}
										unity={data.redeemRequest.unity}
										redeemStep={data.redeemRequest.redeem_step}
									/>
								) : (
									<ConstructorDeliveryModal
										open={Boolean(deliveryModal)}
										delivery={deliveryModal.data}
										modalType={deliveryModal.modalType}
										onClose={() => setDeliveryModal(null)}
										deliveryType={data.redeemRequest.type}
										redeemRequestProducts={data.redeemRequestProducts}
										unity={data.redeemRequest.unity}
									/>
								))}
						</DeliveryContext.Provider>
					)}
				</Grid>
				<Grid item xs={12}>
					{!data?.redeemRequest ? (
						<Skeleton variant='rectangular' height={300} />
					) : (
						<TableCardTreeView
							elevation={0}
							borderRadius={1}
							loading={!rrFiles}
							title={intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.related_documentation.title' })}
							layoutColumns={12}
							columns={relatedFiles}
							relatedColumns={relatedFiles}
							rows={rrFiles?.map((document: Document) => ({ ...document, user }))}
							actions={[
								{
									displayName: '+ Cargar archivo',
									icon: <Upload />,
									disabled: false,
									type: 'Button',
									variant: 'text',
									onClick: () => setFileUploadDialogOpen(true),
								},
							]}
							budgetAcceptanceBlock={
								user.type_external === Role.CONSTRUCTOR &&
								data?.redeemRequest?.status === 2 &&
								data?.redeemRequest?.type === RedeemRequestTypes.SHIPPING &&
								!!data?.redeemRequest?.shipping_price ? (
									<>
										<Box
											sx={{
												marginTop: 2,
												borderRadius: '8px',
												padding: 2,
												backgroundColor: 'rgba(64, 106, 255, 0.19)',
												color: '#0047FF',
												display: 'flex',
												alignItems: 'center',
												gap: 2,
												width: '100%',
											}}
										>
											<ErrorOutlineIcon />
											<Typography color={'#0047FF'}>
												Por favor revise el presupuesto de envío adjunto y confirme su aceptación o rechazo
											</Typography>
										</Box>
										<Box sx={{ display: 'flex', gap: 4, justifyContent: 'center', marginTop: 4 }}>
											<Button
												variant='contained'
												size='medium'
												startIcon={<ThumbUp />}
												sx={{
													background: '#2E7D32',
													color: 'white',
													'&:hover': {
														opacity: 0.9,
													},
												}}
												onClick={onBudgetAcceptClick}
											>
												Aceptar
											</Button>
											<Button
												variant='contained'
												size='medium'
												startIcon={<ThumbDown />}
												sx={{ color: '#D32F2F', border: '1px solid #D32F2F' }}
												onClick={onBudgetRejectClick}
											>
												Rechazar
											</Button>
										</Box>
									</>
								) : (
									<></>
								)
							}
						/>
					)}
				</Grid>

				<FileUploadDialog
					redeemRequest={true}
					dataRedeemRequest={data?.redeemRequest}
					open={fileUploadDialogOpen}
					types={
						user.type_external === Role.SELLER
							? sellerAllowedFileTypes.filter((fileType) => {
									if ([13, 14, 15].includes(fileType.id)) {
										return (
											rrFiles?.filter(
												(document: Document) =>
													document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
											).length > 0
										);
									}
									return true;
							  })
							: constructorAllowedFileTypes
					}
					documents={
						rrFiles?.filter(
							(document: Document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
						) || []
					}
					user={user}
					onClose={(event, reason, newFile) => {
						setFileUploadDialogOpen(false);
						if (newFile) forceInsertNewFile(newFile);

						if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile) fetchFiles();
					}}
				/>
				<BudgetAcceptanceDialog
					open={showBudgetAcceptanceDialog}
					onClose={() => setShowBudgetAcceptanceDialog(false)}
					loading={loadingDialog}
					onConfirm={handleAccept}
				/>
				<RejectedRedeemTokensDialog
					open={showBudgetRejectDialog}
					loading={loadingDialog}
					onClose={() => setShowBudgetRejectDialog(false)}
					onBack={() => setShowBudgetRejectDialog(false)}
					onReject={handleReject}
				/>
				<EndRedeemDialog
					open={openRedeemModal}
					onClose={() => setOpenRedeemModal(false)}
					redeemRequestId={id as string}
					refresh={init}
					tokensPendingToDeliver={
						Number(
							data?.redeemRequestProducts
								.reduce((acc: number, product: any) => acc + product.unassigned * product.equivalence, 0)
								.toFixed(data.redeemRequest.token_decimals ?? 2)
						) || 0
					}
				/>
			</Grid>
		</BasePageFrame>
	);
};

export default Redeemview;
