import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import hideDrawerMenuState from '../../atoms/hide-drawer-menu-state';
import userState from '../../atoms/user-state';
import FullScreenLoading from '../full-screen-loading';
import { Button } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import client from '../../clients/client';
import ListComments from './dialogs/list-comments';
import { useMobile } from '../../hooks/use-mobile';
import CrispCTAButton from '../crisp-cta-button';

const WrapperProposalBackoffice = () => {
	const { listId, orderId } = useParams();
	const navigate = useNavigate();
	const intl = useIntl();
	const isMobile = useMobile();

	const user = useRecoilValue(userState);
	const setHideDrawerMenu = useSetRecoilState(hideDrawerMenuState);

	const [loading, setLoading] = useState(true);
	const [proposalId, setProposalId] = useState<string | null>(null);
	const [openListCommentsDialog, setOpenListCommentsDialog] = useState(false);
	const [unreadMessages, setUnreadMessages] = useState<number | undefined>();
	const [loadingMessages, setLoadingMessages] = useState(true);
	const iframeRef = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		const verifiedIfListIdIsMappingNewQuoteIds = async () => {
			await client
				.geProposalIdFromListId({ token: user.token, listId: listId as string })
				.then((response) => {
					const { proposal_id } = response;
					setLoading(false);

					if (!proposal_id) {
						// if there is no quote_id matching the list_id on the table cotizaciones_quote_mapping, then navigate back to the list
						if (orderId) {
							navigate(`/list/${listId}/${orderId}`);
						} else {
							navigate(`/list/${listId}`);
						}
					} else {
						setProposalId(proposal_id);
					}
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
					if (orderId) {
						navigate(`/list/${listId}/${orderId}`);
					} else {
						navigate(`/list/${listId}`);
					}
				});
		};

		if (listId) {
			setLoading(true);
			verifiedIfListIdIsMappingNewQuoteIds();
		}
	}, [listId, navigate, orderId, user.token]);

	useEffect(() => {
		const fetch = async () => {
			await client
				.get({ listId, orderId, user })
				.then((response) => {
					const { unread_messages } = response;
					setUnreadMessages(unread_messages);
					setLoadingMessages(false);
				})
				.catch((error) => {
					console.error(error);
					setLoadingMessages(false);
				});
		};

		if (listId) {
			fetch();
		}
	}, [listId, orderId, user]);

	useEffect(() => {
		if (!isMobile) {
			setHideDrawerMenu(true);
		}
		return () => {
			setHideDrawerMenu(false);
		};
	}, [isMobile, setHideDrawerMenu]);

	const token = user.token;
	const encodedParam = btoa(JSON.stringify({ token, proposalId }));

	return (
		<div className='w-full min-h-dvh flex flex-col pt-[64px] overflow-hidden'>
			{loading && <FullScreenLoading />}
			<div className='grow-0 w-full h-fit py-2 flex justify-center items-center'>
				{/* same width applied on Component ProductDetails from nuqlea-studio-next repository, to get the same horizontal alignment from the card of the proposal */}
				<div className='flex justify-between items-center min-w-80 max-w-80 sm:min-w-[600px] sm:max-w-[600px] md:min-w-[720px] md:max-w-[720px] lg:min-w-[960px] lg:max-w-[960px] xl:min-w-[1140px] xl:max-w-[1140px] 2xl:min-w-[1420px] 2xl:max-w-[1420px]'>
					<Button
						onClick={() => (orderId ? navigate(`/list/${listId}/${orderId}`) : navigate(`/list/${listId}`))}
						startIcon={<KeyboardBackspace />}
					>
						{intl.formatMessage({ id: 'common.back' })}
					</Button>
					<div className='flex justify-end gap-2'>
						<CrispCTAButton />
						<Button onClick={() => setOpenListCommentsDialog(true)} variant={'outlined'} disabled={loadingMessages}>
							{intl.formatMessage({ id: 'list.details.list_comments.activator_label' })}
							{unreadMessages && unreadMessages > 0 ? ` (${unreadMessages})` : ''}
						</Button>
					</div>
				</div>
			</div>
			<div className='w-full grow-[2]'>
				{proposalId && (
					<iframe
						ref={iframeRef}
						style={{ width: '100%', height: '100%', border: 'none' }}
						src={`${process.env.REACT_APP_BACKOFFICE_BASE_URL}/shared/proposals/${encodedParam}`}
						title='proposal'
					/>
				)}
			</div>
			<ListComments
				open={openListCommentsDialog}
				listId={listId}
				orderId={orderId}
				user={user}
				onClose={() => setOpenListCommentsDialog(false)}
				onReadComments={() => setUnreadMessages(undefined)}
			/>
		</div>
	);
};

export default WrapperProposalBackoffice;
